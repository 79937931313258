<template>
  <section>

    <el-dialog :title="dialogTitle"
               :visible.sync="addzhibiao"
               v-model="addzhibiao"
               :close-on-click-modal="false"
               @closed="checkclose"
               center
               >
      <el-form ref="addzhibiaoForm" :model="addzhibiaoForm" label-width="80px" size="mini" :rules="addzhibiaoFormRules">
        <el-form-item v-show="mytype==1" label="配置名称" prop="TemplateName">
          <el-input v-model="addzhibiaoForm.TemplateName" placeholder="简述配置名称 如：流程批复指标"></el-input>
        </el-form-item>
        <el-form-item v-show="mytype==1" label="是否公开" prop="Public">
          <el-radio-group v-model="addzhibiaoForm.IsPublic" size="mini">
            <el-radio :label="false">私有</el-radio>
            <el-radio :disabled="true" :label="true">公开</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-show="mytype==1" label="创建人：" prop="CreatedBy">
          <span style="font-size:9px;font-weight:bold;">{{addzhibiaoForm.CreatedByStr}}</span>
        </el-form-item>
        <el-button-group>
          <el-button type="default" size="mini" icon="el-icon-plus" @click="handleAddZhibiaoLine(-1)">添加</el-button>
          <el-button type="default" size="mini" icon="el-icon-minus" @click="handleDelZhibiaoLine()">删除选中</el-button>
        </el-button-group>
        <vxe-table
          border
          show-overflow
          keep-source
          ref="xTable"
          align="center"
          max-height="400"
          :data="zhibiaoDetail"
          :edit-rules="validRules"
          :edit-config="{trigger: 'click', mode: 'cell', icon: 'fa fa-pencil', showStatus: true}">
          <vxe-column type="checkbox" width="60"></vxe-column>
          <vxe-column type="seq" width="50" title="序号"></vxe-column>
          <vxe-column field="name" title="指标项" min-width="100px" :edit-render="{autofocus: '.vxe-input--inner', defaultValue: ''}">
            <template #edit="{ row }">
              <vxe-input v-model="row.name" type="text" maxlength="35"></vxe-input>
            </template>
          </vxe-column>
          <vxe-column field="type" title="值类型" :edit-render="{defaultValue:1}">
            <template #default="{ row }">
              <span>{{ formatType(row.type) }}</span>
            </template>
            <template #edit="{ row }">
              <vxe-select v-model="row.type" transfer align='center'>
                <vxe-option v-for="item in typeList" :key="item.value" :value="item.value" :label="item.label"></vxe-option>
              </vxe-select>
            </template>
          </vxe-column>
          <vxe-column field="length" title="值长度"  :edit-render="{defaultValue: 10}">
            <template #edit="{ row }">
              <vxe-input v-model="row.length" min="1" align="center" max="50" type="integer"></vxe-input>
            </template>
          </vxe-column>
          <vxe-column field="unit" title="值单位" :edit-render="{autofocus: '.vxe-input--inner', defaultValue: '支'}">
            <template #edit="{ row }">
              <vxe-input v-model="row.unit" type="text" maxlength="5"></vxe-input>
            </template>
          </vxe-column>
          <vxe-column field="mustinput" title="值必填" :edit-render="{defaultValue:true}">
            <template #default="{ row }">
              <span>{{ formatMustinput(row.mustinput) }}</span>
            </template>
            <template #edit="{ row }">
              <vxe-select v-model="row.mustinput" transfer>
                <vxe-option v-for="item in mustinputList" :key="item.value" :value="item.value" :label="item.label"></vxe-option>
              </vxe-select>
            </template>
          </vxe-column>
          <!-- <vxe-column field="default" title="默认值" :edit-render="{autofocus: '.vxe-input--inner', defaultValue: ''}">
            <template #edit="{ row }">
              <vxe-input v-model="row.default" type="text" maxlength="50"></vxe-input>
            </template>
          </vxe-column> -->
          <!-- <vxe-column field="date12" title="日期" sortable :edit-render="{}">
            <template #edit="{ row }">
              <vxe-input v-model="row.date12" type="date" transfer></vxe-input>
            </template>
          </vxe-column> -->
        </vxe-table>
        
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click.native="handleSubmitTemplate">提交</el-button>
        <el-button type="default" @click.native=" addzhibiao = false ">关闭</el-button>
      </div>
    </el-dialog>

  </section>
</template>
<script>
import store from '../store'
import util from '../../util/date'
import { Loading } from 'element-ui'
import {
  AddTemplate,UpdateTemplate,DelTemplate,RenameTemplate,ChangeTemplatePublicStatus,QueryPageTemplateByUserCode,QueryTemplateDetailByTemplateId
} from '../api/oa'
import dialogDrag from '../../util/dialogDrag'
export default {
  name: 'zhibiaoconfig',
  components: { util, dialogDrag },
  data() {
    return {
      added:0,
      dialogTitle:'',
      mytype:'',
      loading: '',
      currentzbPage: 1, // 当前页码
      totalzb: null, // 总条数
      totaldatazb: null,
      pageSizezb: 5, // 每页的数据条数
      pagezb: 1,
      currentRow: null,
      currentZhibiaoRow: null,
      zhibiaolist:[],
      lookdetail:[],
      zhibiaopeizhi: false,
      zhibiaoDetail:[],
      addzhibiao: false,
      addzhibiaoForm:{
        TemplateName:'',
        Remark:'',
        IsPublic:false,
        CreatedBy:''
      },
      typeList: [
        { label: '数字', value: 1 },
        { label: '文本', value: 2 },
        // { label: '日期', value: 3 },
        // { label: '时间', value: 4 }
      ],
      mustinputList:[
        { label: '必填', value: true },
        { label: '非必填', value: false },
      ],
      radioPublic:false,
      addzhibiaoFormRules:{
        TemplateName: [
          { required: true, message: '请填写指标配置名称', trigger: 'blur' }
        ],
        IsPublic: [
          { required: true, message: '请选择是否公开', trigger: 'blur' }
        ],
      },
      validRules: {
        name: [
          { required: true, message: '指标项必须填写' },
        ],
        // unit: [
        //   { required: true, message: '指标值单位必须填写' },
        // ],
      },
    }
  },
  props: ['headerText', 'data', 'type' ], // 接收父组件的方法
  watch: {
    content() {
      // this.editor.txt.html(this.content)
    }
  },
  methods: {
    handleChoose(row)
    {
      // console.log(row);
      this.$emit('chooseCallFunction', row)
    },
    formatMustinput (value) {
      for(var a=0;a<this.mustinputList.length;a++)
      {
        if(this.mustinputList[a].value==value)
        {
          return this.mustinputList[a].label;
        }
      }
      return ''
    },
    formatType (value) {
      for(var a=0;a<this.typeList.length;a++)
      {
        if(this.typeList[a].value==value)
        {
          return this.typeList[a].label;
        }
      }
      return ''
    },
    handleSubmitTemplate()
    {
      this.$refs.addzhibiaoForm.validate((valid) => {
        if (!valid) {
          this.$message({
            message: '请完整填写表单',
            type: 'warning'
          });
          return;
        }else{
          const $table = this.$refs.xTable
          var tableData = $table.getTableData().tableData;
          // console.log(tableData);
          
          if(tableData.length==0)
          {
              this.$message({
                message: '请填写指标配置项',
                type: 'warning'
              });
              return;
          }
          var iserr = false;
          for(var a=0;a< tableData.length;a++)
          {
            if(!tableData[a].name.trim())
            {
              this.$message({
                message: '请完整填写指标配置项',
                type: 'warning'
              });
              iserr = true;
              break;
            }
          }
          if(!iserr)
          {
            this.$confirm("确定要提交此指标配置吗？", "提示", {}).then(() => {
              if(this.mytype==0)
              {
                this.addzhibiaoForm.IsTemp = true;
              }else if(this.mytype==1)
              {
                this.addzhibiaoForm.IsTemp = false;
              }
              var para =Object.assign({},this.addzhibiaoForm);
              var detail = new Array();
              tableData.forEach(element => {
                var obj={
                  FieldName:element.name,
                  FieldUnit:element.unit,
                  FieldType:element.type,
                  FieldLength:element.length,
                  FieldMustInput:element.mustinput,
                  // FieldDefault:element.default
                }
                detail.push(obj);
              });
              para.Details=detail;
              // console.log(para)
              
              // return;
              if(this.addzhibiaoForm.Id)
              {
                UpdateTemplate(para).then((res) => {
                  // console.log(res);
                  if(res.status==200)
                  {
                    this.$message({
                      message: '修改指标配置成功',
                      type: 'success'
                    });
                    this.added=1;
                    this.addzhibiao = false;
                    this.radioPublic = this.addzhibiaoForm.IsPublic;
                  }
                })
              }else{
                AddTemplate(para).then((res) => {
                  // console.log(res);
                  if(res.status==200)
                  {
                    this.$message({
                      message: '新增指标配置成功',
                      type: 'success'
                    });
                    this.added=1;
                    this.addzhibiao = false;
                    this.radioPublic = this.addzhibiaoForm.IsPublic;
                  }
                })
              }
            }).catch(()=>{});
          }
        }
      })
    },
    handleDelZhibiaoLine()
    {
      const xtable = this.$refs.xTable;
      xtable.removeCheckboxRow();
    },
    handleAddZhibiaoLine(row)
    {
      const xtable = this.$refs.xTable;
      const record = {};
      const { row: newRow } = xtable.insertAt(record, row)
      xtable.setActiveCell(newRow, 'name')
    },
    handleAddZhibiao()
    {
      this.zhibiaoDetail = [];
      this.addzhibiao = true;
    },
    myname() {
      var user = JSON.parse(window.localStorage.user)
      return user.name
    },
    checkclose() {
      this.addzhibiao = false;
      this.$emit('closeFunction', {added:this.added})
    },
    Loadingstart() {
      this.loading = Loading.service({
        lock: true,
        text: '加载中...',
        background: 'rgba(0,0,0,0.1)'
      })
    },
    Loadignend() {
      this.loading.close()
    },
  },
  mounted() {
    if (this.headerText) {
      this.myheadText = this.headerText
    }
    if (this.type) {
      this.mytype = this.type
      console.log(this.mytype)
    }
    if(this.mytype==0)
    {
      this.addzhibiaoForm.TemplateName='新建指标';
    }
    var user = JSON.parse(window.localStorage.user);
    this.addzhibiaoForm.CreatedId = user.sub;
    this.addzhibiaoForm.CreatedBy = user.name;
    this.addzhibiaoForm.CreatedDptId = window.localStorage.deptId;
    this.addzhibiaoForm.CreatedDpt = window.localStorage.deptName;
    // this.addzhibiaoForm.CreatedByStr=this.addzhibiaoForm.CreatedDpt+'-'+user.name;
    this.addzhibiaoForm.CreatedByStr=user.name;
    this.addzhibiao = true;
    if(this.data)
    {
      this.dialogTitle = '编辑指标配置';
      this.addzhibiao = true;
      this.addzhibiaoForm.Id = this.data.Id;
      this.addzhibiaoForm.TemplateName = this.data.TemplateName;
      this.addzhibiaoForm.Remark = this.data.Remark;
      this.addzhibiaoForm.CreatedByStr = this.data.CreatedBy;

      let para = { templateId: this.data.Id };
      QueryTemplateDetailByTemplateId(para).then((res) => {
        // console.log(res.data.response)
        var arr = new Array();
        res.data.response.forEach(element => {
          arr.push({
            id:element.Id,
            name:element.FieldName,
            type:element.FieldType,
            length:element.FieldLength,
            unit:element.FieldUnit,
            mustinput:element.FieldMustInput
          })
        });
        this.zhibiaoDetail = arr;
      });
    }
    // this.queryTemplateList();
  }
}
</script>
<style scoped>
.active {
  background-color: #00000014;
}
/deep/ .el-collapse-item__arrow {
  margin: 0;
  transition: transform 0.3s;
  font-weight: 300;
}
/deep/ .el-dialog.is-fullscreen {
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  height: 100%;
  overflow: hidden;
}
</style>
